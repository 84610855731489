import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Palettes from './Palettes';

const MarkAsSent = ({ refetch }) => {
   const [palettes, setPalettes] = useState([]);
   const [groupPublicIdError, setGroupPublicIdError] = useState('');
   const formik = useFormik({
      initialValues: {
         groupPublicId: '',
      },
      onSubmit: async ({ groupPublicId }) => {
         setPalettes([]);
         const { data } = await axios.get(
            `/warehouse/groups/by-public-id/${groupPublicId}/palettes`,
         );
         setPalettes(data.palettes);
      },
   });
   useEffect(() => {
      const itIsNotGroupPublicId =
         formik.values.groupPublicId &&
         formik.values.groupPublicId.slice(0, 1).toUpperCase() !== 'G';
      if (itIsNotGroupPublicId) {
         setGroupPublicIdError(
            'Wprowadzasz numer zamówienia, wkrótce będziesz musiał korzystać z numeru grupy.',
         );
      } else {
         setGroupPublicIdError('');
      }
   }, [formik.values.groupPublicId]);

   return (
      <Container>
         <h2>Oznacz wysłane</h2>
         <Form onSubmit={formik.handleSubmit}>
            <div>
               <label htmlFor="groupPublicId">
                  Id grupy
                  <input
                     type="text"
                     className="form-control"
                     name="groupPublicId"
                     placeholder="Id grupy"
                     value={formik.values.groupPublicId}
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                  />
               </label>
               <Search type="submit" className="btn btn-success">
                  Szukaj
               </Search>
               {groupPublicIdError && (
                  <div>
                     <p className="text-danger">{groupPublicIdError}</p>
                  </div>
               )}
            </div>
         </Form>
         <Palettes palettes={palettes} refetch={refetch} />
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const Form = styled.form`
   display: flex;
   align-items: flex-end;
`;

const Search = styled.button`
   margin-left: 1rem;
`;

export default MarkAsSent;
