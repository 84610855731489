import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Context } from '../Context';

const RemoveButton = ({ order }) => {
   const dispatch = useDispatch();
   const { setLoading } = useContext(Context);
   const remove = () => {
      setLoading(true);
      axios
         .patch(`/production/orders/${order.id}/withdraw-from-paint-shop`)
         .then(() =>
            dispatch({
               type: 'WAREHOUSE_ORDER_REMOVED',
               payload: {
                  orderId: order.id,
               },
            }),
         )
         .finally(() => setLoading(false));
   };

   return (
      <Container>
         <div>
            <button
               type="button"
               className="btn btn-danger"
               onClick={remove}
               disabled={!order.canBeRemoved}
            >
               <FontAwesomeIcon icon={faTrashAlt} />
            </button>
         </div>
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
`;

export default RemoveButton;
