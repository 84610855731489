import axios from 'axios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { isGroupWithConstructionAndPolesPalette } from 'Shared/Warehouse/OrderPartsGroup';
import {
   attribute,
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
   TYPE_CONSTRUCTION_AND_POLES_PALETTE,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const renderProducts = (
   parts,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
) => {
   if (
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      )
   ) {
      const products = parts.map((op) => {
         return <Products key={op.id} products={op.products} />;
      });
      return products;
   }
   return 'Paleta słupów';
};

const Palettes = ({ palettes, refetch }) => {
   const [info, setInfo] = useState('');
   const [sentId, setSentId] = useState('');
   const submit = async (e, groupId, finalPaletteId) => {
      setInfo('');
      setSentId('');
      await axios.post(`/warehouse/groups/${groupId}/final-palettes/${finalPaletteId}/sent`);
      setInfo('Zapisane.');
      setSentId(finalPaletteId);
      refetch();
   };
   useEffect(() => {
      setInfo('');
      setSentId('');
   }, []);
   const groupWithConstructionAndPolesPalette = isGroupWithConstructionAndPolesPalette({
      palettes,
   });
   const palettesRendered = palettes.map((p, i) => {
      const next = palettes[i + 1];
      const nextPaletteIsConstructionAndPoles =
         next && next.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE;
      const paletteType = guessPaletteType(
         groupWithConstructionAndPolesPalette,
         p.type,
         nextPaletteIsConstructionAndPoles,
      );
      const products = renderProducts(
         p.orderParts,
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      );
      const parts = p.orderParts.map((op) => {
         return (
            <tr key={op.id}>
               <td>
                  <ZamowieniaLink
                     displayId={op.order.zamowieniaId}
                     zamowieniaId={op.order.zamowieniaId}
                  />
               </td>
               <td>{products}</td>
            </tr>
         );
      });
      return (
         <tr key={p.id}>
            <td>{p.companyName}</td>
            <td>{p.publicId}</td>
            <td>{attribute('width', p.attributes)}</td>
            <td>{attribute('height', p.attributes)}</td>
            <td>{attribute('length', p.attributes)}</td>
            <td>{attribute('weight', p.attributes)}</td>
            <td>{paletteType}</td>
            <td>
               <table className="table table-hover table-sm">
                  <thead>
                     <tr>
                        <th>Zamówienia / części zamówień</th>
                        <th>Produkty</th>
                     </tr>
                  </thead>
                  <tbody> {parts} </tbody>
               </table>
            </td>
            <td>
               {p.sentAt || (
                  <>
                     <Submit
                        type="button"
                        className="btn btn-success"
                        onClick={(e) => submit(e, p.groupId, p.id)}
                     >
                        Wysłana
                     </Submit>
                     <span>{sentId === p.id && info}</span>
                  </>
               )}
            </td>
         </tr>
      );
   });

   return (
      <Container>
         <table className="table table-hover">
            <thead>
               <tr>
                  <th>Klient</th>
                  <th>Paleta</th>
                  <th>Szerokość</th>
                  <th>Wysokość</th>
                  <th>Długość</th>
                  <th>Waga</th>
                  <th>Typ</th>
                  <th>Zamówienia / części zamówień</th>
                  <th>Wysłana</th>
               </tr>
            </thead>
            <tbody>{palettesRendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const Submit = styled.button`
   margin: 0 1rem;
`;

export default Palettes;
