import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { attribute, paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const FinalPalette = ({ finalPalette }) => {
   const ordersPublicIds = finalPalette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink displayId={op.publicId} zamowieniaId={op.order.zamowieniaId} />
         </li>
      );
   });
   const products = finalPalette.orderParts.map((op) => (
      <Products key={op.id} products={op.products} />
   ));
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Część: {finalPalette.publicId || ''}</th>
               <th>Pakowane</th>
               <th>Zamówienia / części zamówień</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <ul className="list-unstyled">
                     <li>Szerokość: {attribute('width', finalPalette.attributes)}</li>
                     {attribute('width2', finalPalette.attributes) && (
                        <li>Szerokość 2: {attribute('width2', finalPalette.attributes)}</li>
                     )}
                     {attribute('width3', finalPalette.attributes) && (
                        <li>Szerokość 2: {attribute('width3', finalPalette.attributes)}</li>
                     )}
                     <li>Wysokość: {attribute('height', finalPalette.attributes)}</li>
                     {attribute('height2', finalPalette.attributes) && (
                        <li>Wysokość 2: {attribute('height2', finalPalette.attributes)}</li>
                     )}
                     {attribute('height3', finalPalette.attributes) && (
                        <li>Wysokość 2: {attribute('height3', finalPalette.attributes)}</li>
                     )}
                     <li>Długość: {attribute('length', finalPalette.attributes)}</li>
                     <li>Waga: {attribute('weight', finalPalette.attributes)}</li>
                     <li>Typ palety: {paletteTypes[finalPalette.type]}</li>
                  </ul>
               </td>
               <td>
                  <ul className="list-unstyled">
                     <li>Przez: {finalPalette.packedByFilledInManually || ''}</li>
                     <li>Kiedy: {finalPalette.packedAt || ''}</li>
                  </ul>
               </td>
               <td>
                  <ul className="list-unstyled">{ordersPublicIds}</ul>
               </td>
               <td>{products}</td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;
