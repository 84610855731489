import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   attribute,
   guessPaletteType,
   TYPE_CONSTRUCTION_AND_POLES_PALETTE,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const renderProducts = (part, nextPaletteIsConstructionAndPoles) => {
   if (!nextPaletteIsConstructionAndPoles) {
      return 'Paleta słupów';
   }
   return <Products products={part.products} />;
};

const Palettes = ({ palettes }) => {
   const palettesRendered = palettes.map((p, i) => {
      const next = palettes[i + 1];
      const nextPaletteIsConstructionAndPoles =
         next && next.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE;
      const paletteType = guessPaletteType(true, p.type, nextPaletteIsConstructionAndPoles);
      const parts = p.orderParts.map((op) => {
         const products = renderProducts(op, nextPaletteIsConstructionAndPoles);
         return (
            <tr key={op.id}>
               <td>
                  <ZamowieniaLink displayId={op.publicId} zamowieniaId={op.order.zamowieniaId} />
               </td>
               <td>{products}</td>
            </tr>
         );
      });
      return (
         <tr key={p.id}>
            <td>{p.group.companyName}</td>
            <td>{p.group.publicId}</td>
            <td>{p.publicId}</td>
            <td>{attribute('width', p.attributes)}</td>
            <td>{attribute('height', p.attributes)}</td>
            <td>{attribute('length', p.attributes)}</td>
            <td>{attribute('weight', p.attributes)}</td>
            <td>{paletteType}</td>
            <td>{parts}</td>
            <td>{p.sentAt}</td>
         </tr>
      );
   });

   return (
      <Container>
         <table className="table table-hover">
            <caption>
               U góry tabeli znajdą się te wysłane przed momentem, a na dole te wysłane najwcześniej
               (np. z rana).
            </caption>
            <thead>
               <tr>
                  <th>Klient</th>
                  <th>Id grupy</th>
                  <th>Identyfikacja</th>
                  <th>Szerokość</th>
                  <th>Wysokość</th>
                  <th>Długość</th>
                  <th>Waga</th>
                  <th>Typ</th>
                  <th>Zamówienia / części zamówień</th>
                  <th>Wysłana</th>
               </tr>
            </thead>
            <tbody>{palettesRendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

export default Palettes;
