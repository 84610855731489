import {
   ATTR_INFO_ABOUT_BOX,
   ATTR_INFO_ABOUT_EQUIPMENT,
   attribute as orderAttribute,
} from 'Shared/Order';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   guessPaletteType,
   shouldRenderProductsInPaletteConstructionAndPoles,
   attribute as paletteAttribute,
} from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

import PlannedToTransport from './PlannedToTransport';

const FinalPalette = ({
   finalPalette,
   groupWithConstructionAndPolesPalette,
   nextPaletteIsConstructionAndPoles,
}) => {
   const paletteType = guessPaletteType(
      groupWithConstructionAndPolesPalette,
      finalPalette.type,
      nextPaletteIsConstructionAndPoles,
   );
   const renderProductsInPaletteConstructionAndPoles =
      shouldRenderProductsInPaletteConstructionAndPoles(
         groupWithConstructionAndPolesPalette,
         nextPaletteIsConstructionAndPoles,
      );
   const parts = finalPalette.orderParts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink displayId={p.publicId} zamowieniaId={p.order.zamowieniaId} />
            </td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_EQUIPMENT)}</td>
            <td>{orderAttribute(p.order.attributes, ATTR_INFO_ABOUT_BOX)}</td>
            <td>
               {renderProductsInPaletteConstructionAndPoles && <Products products={p.products} />}
               {!renderProductsInPaletteConstructionAndPoles && 'Paleta słupów'}
            </td>
         </tr>
      );
   });
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Paleta</th>
               <th>Pakowane</th>
               <th>Planowanie transportu</th>
               <th>Części zamówień</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>
                  <ul className="list-unstyled">
                     <li>Część: {finalPalette.publicId || ''}</li>
                     <li>Szerokość: {paletteAttribute('width', finalPalette.attributes)}</li>
                     {paletteAttribute('width2', finalPalette.attributes) && (
                        <li>Szerokość 2: {paletteAttribute('width2', finalPalette.attributes)}</li>
                     )}
                     {paletteAttribute('width3', finalPalette.attributes) && (
                        <li>Szerokość 2: {paletteAttribute('width3', finalPalette.attributes)}</li>
                     )}
                     <li>Wysokość: {paletteAttribute('height', finalPalette.attributes)}</li>
                     {paletteAttribute('height2', finalPalette.attributes) && (
                        <li>Wysokość 2: {paletteAttribute('height2', finalPalette.attributes)}</li>
                     )}
                     {paletteAttribute('height3', finalPalette.attributes) && (
                        <li>Wysokość 2: {paletteAttribute('height3', finalPalette.attributes)}</li>
                     )}
                     <li>Długość: {paletteAttribute('length', finalPalette.attributes)}</li>
                     <li>Waga: {paletteAttribute('weight', finalPalette.attributes)}</li>
                     {groupWithConstructionAndPolesPalette &&
                        !nextPaletteIsConstructionAndPoles && (
                           <li>
                              <span>Ilość słupów: </span>
                              {paletteAttribute('numberOfPoles', finalPalette.attributes)}
                           </li>
                        )}
                     <li>Typ: {paletteType}</li>
                  </ul>
               </td>
               <td>
                  <ul className="list-unstyled">
                     <li>Przez: {finalPalette.packedByFilledInManually || ''}</li>
                     <li>Kiedy: {finalPalette.packedAt || ''}</li>
                  </ul>
               </td>
               <td>
                  <PlannedToTransport finalPalette={finalPalette} />
               </td>
               <td>
                  <table className="table table-hover table-sm">
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Osprzęt</th>
                           <th>Pudełko</th>
                           <th>Produkty</th>
                        </tr>
                     </thead>
                     <tbody>{parts}</tbody>
                  </table>
               </td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;
