import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'designSystem';
import {
   isGroupWithConstructionAndPolesPalette,
   orderPartsGroupStatuses,
} from 'Shared/Warehouse/OrderPartsGroup';

import { TYPE_CONSTRUCTION_AND_POLES_PALETTE } from '../Palette';

import FinalPalette from './FinalPalette';
import PaletteLabel from './PaletteLabel/PaletteLabel';

const GroupWithFinalPalettes = ({ group }) => {
   const groupWithConstructionAndPolesPalette = isGroupWithConstructionAndPolesPalette(group);
   const [paletteLabelModal, setPaletteLabelModal] = useState(false);

   const finalPaletts = group.palettes.map((p, i) => {
      const next = group.palettes[i + 1];
      const nextPaletteIsConstructionAndPoles =
         next && next.type === TYPE_CONSTRUCTION_AND_POLES_PALETTE;
      return (
         <tr key={p.id}>
            <td>
               <FinalPalette
                  finalPalette={p}
                  groupWithConstructionAndPolesPalette={groupWithConstructionAndPolesPalette}
                  nextPaletteIsConstructionAndPoles={nextPaletteIsConstructionAndPoles}
               />
            </td>
         </tr>
      );
   });

   return (
      <tr key={group.id}>
         <td>
            <Description>
               <span>Klient: {group.companyName}</span>
               <span>Utworzono: {group.createdAt}</span>
               <span>Spakowano na gotowo: {group.packedAt}</span>
               <span>Wysłano: {group.sentAt}</span>
               <span>Status: {orderPartsGroupStatuses[group.status]}</span>
               <span>Id grupy: {group.publicId}</span>
               {paletteLabelModal && (
                  <PaletteLabel
                     groupId={group.publicId}
                     palettes={group.palettes}
                     closePaletteLabelModal={() => setPaletteLabelModal(false)}
                  />
               )}
               <Button
                  type="button"
                  className="btn btn-info"
                  color="secondary"
                  data-dismiss="modal"
                  onClick={() => {
                     setPaletteLabelModal(!paletteLabelModal);
                  }}
               >
                  <ButtonSpan>Utwórz etykietę do druku</ButtonSpan>
                  <ButtonSpan>
                     <FontAwesomeIcon icon={faPrint} />
                  </ButtonSpan>
               </Button>
            </Description>
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th />
                  </tr>
               </thead>
               <tbody>{finalPaletts}</tbody>
            </table>
         </td>
      </tr>
   );
};

const ButtonSpan = styled.span`
   padding: 5px;
`;

const Description = styled.div`
   display: flex;
   flex-direction: column;
`;

export default GroupWithFinalPalettes;
